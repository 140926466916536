import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleLink from '../components/article-link';

import './post-list.scss';

const PostList = ({
  data: { allWpCategory: { edges } },
  pageContext: { name: categoryName },
}: any) => {
  const [categoryEdges] = edges;
  const posts = categoryEdges.node.posts.nodes;

  return (
    <Layout categories={categoryName}>
      <SEO />
      <div className="post-list">
        {posts.map(({
          title, slug, featuredImage, date,
        }: any) => (
          <ArticleLink
            title={title}
            imageData={featuredImage?.node?.localFile}
            slug={slug}
            date={date}
            key={slug}
          />
        ))}
      </div>
    </Layout>
  );
};

export default PostList;

export const postQuery = graphql`
query CategoryByName($name: String!) {
  allWpCategory(filter: {name: {eq: $name}}) {
    edges {
      node {
        posts {
          nodes {
            title
            date(formatString: "DD.MM.YY")
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 314
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      aspectRatio: 1.7375
                    )
                  }
                }
              }
            }
          }
        }
        name
      }
    }
  }
}
`;
